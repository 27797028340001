export const saveToLocalStorage = (key: string, value: any) => {
    localStorage.setItem(key, value);
};

export const getFromLocalStorage = (key: string) => {
    const data = localStorage.getItem(key);
    return data;
};

export const removeFromLocalStorage = (key: string) => {
    localStorage.removeItem(key);
};
