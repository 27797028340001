import React, { lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import BottomNavigation from "../components/MobileNavigation/BottomNavigation/BottomNavigation";
import TopNavigation from "../components/MobileNavigation/TopNavigation/TopNavigation";

const GetStarted = lazy(
  () => import("../views/onboarding/GetStarted/GetStarted")
);
const LanguageSelection = lazy(
  () => import("../views/onboarding/LanguageSelection/LanguageSelection")
);
const MobileVerify = lazy(
  () => import("../views/onboarding/MobileOTPVerify/MobileVerify")
);
const OTPVerify = lazy(
  () => import("../views/onboarding/MobileOTPVerify/OTPVerify")
);
const LocationAccess = lazy(
  () => import("../views/onboarding/LocationAccess/LocationAccess")
);
const FindDoctor = lazy(() => import("../views/doctor/FindDoctor/FindDoctor"));
const ScheduleListing = lazy(
  () => import("../views/doctor/Schedule/Listing/Listing")
);
const AvailabilityListing = lazy(
  () => import("../views/doctor/AvailabilitySettings/Listing/Listing")
);
const CreateAvailability = lazy(
  () => import("../views/doctor/AvailabilitySettings/Create/Create")
);

const Home = () => {
  return <h1>Home</h1>;
};

const Medicines = () => {
  return <h1>Medicines</h1>;
};

const TestReports = () => {
  return <h1>TestReports</h1>;
};

const HealthTips = () => {
  return <h1>HealthTips</h1>;
};

const IpDash = () => {
  return <h1>IpDash</h1>;
};

const Chat = () => {
  return <h1>Chat</h1>;
};

const Profile = () => {
  return <h1>Profile</h1>;
};
const onboardingRoutes = [
  "/",
  "/get-started",
  "/mobileno",
  "/otp",
  "/location-access",
];
const patientRoutes = [
  "/home",
  "/find-doctor",
  "/medicines",
  "/test-reports",
  "/health-tips",
];

const doctorRoutes = [
  "/availability-settings",
  "/availability-create",
  "/schedule",
  "/ip-dash",
  "/chats",
  "/profile",
];
const doctorBottomNavRoutes = ["/schedule", "/ip-dash", "/chats", "/profile"];
const Router: React.FC = () => {
  const location = useLocation();

  const hideNavFromOnboarding = onboardingRoutes.includes(location.pathname);
  const showNavForPatient = patientRoutes.includes(location.pathname);
  const showTopNavForDoctor = doctorRoutes.includes(location.pathname);

  const showBottomNavForDoctor = doctorBottomNavRoutes.includes(
    location.pathname
  );
  return (
    <>
      {!hideNavFromOnboarding && showNavForPatient && (
        <>
          <TopNavigation />
          <BottomNavigation />
        </>
      )}
      {showTopNavForDoctor && <TopNavigation />}
      {showBottomNavForDoctor && <BottomNavigation />}
      <Routes>
        {/* Onboarding */}
        <Route path="/" element={<LanguageSelection />} />
        <Route path="/get-started" element={<GetStarted />} />
        <Route path="/mobileno" element={<MobileVerify />} />
        <Route path="/otp" element={<OTPVerify />} />
        <Route path="/location-access" element={<LocationAccess />} />

        {/* Patient */}
        <Route path="/home" element={<Home />} />
        <Route path="/find-doctor" element={<FindDoctor />} />
        <Route path="/medicines" element={<Medicines />} />
        <Route path="/test-reports" element={<TestReports />} />
        <Route path="/health-tips" element={<HealthTips />} />

        {/* Doctor */}
        <Route path="/schedule" element={<ScheduleListing />} />
        <Route path="/ip-dash" element={<IpDash />} />
        <Route path="/chats" element={<Chat />} />
        <Route path="/profile" element={<Profile />} />

        <Route
          path="/availability-settings"
          element={<AvailabilityListing />}
        />
        <Route path="/availability-create" element={<CreateAvailability />} />
      </Routes>
    </>
  );
};

export default Router;
