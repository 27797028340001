import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

import style from "./TopNavigation.module.css";

import GetImages from "../../GetImages/GetImages";

import { getFromLocalStorage } from "../../../utils/localStorage";

import { IRoleType } from "../../../models/role";

const navItems = {
  [IRoleType.PATIENT]: [
    {
      path: "/home",
      icon: <GetImages name="HomeNavIcon" width="36" height="36" />,
      title: "Hi Kundan!",
      location: "Durgapur, West Bengal",
      secondIcon: <GetImages name="MessageNavIcon" width="24" height="24" />,
      isShowSearch: true,
    },
    {
      path: "/find-doctor",
      icon: <GetImages name="DoctorNavIcon" width="36" height="36" />,
      title: "Find Doctors",
      location: "Durgapur, West Bengal",
      secondIcon: null,
      isShowSearch: true,
    },
    {
      path: "/medicines",
      icon: <GetImages name="MedicineNavIcon" width="36" height="36" />,
      title: "Get Medicines",
      location: "Durgapur, West Bengal",
      secondIcon: null,
      isShowSearch: true,
    },
    {
      path: "/test-reports",
      icon: <GetImages name="TestNavIcon" width="36" height="36" />,
      title: "Book Tests",
      location: "Durgapur, West Bengal",
      secondIcon: <GetImages name="CartNavIcon" width="24" height="24" />,
      isShowSearch: true,
    },
    {
      path: "/health-tips",
      icon: <GetImages name="TvNavIcon" width="36" height="36" />,
      title: "Health Hub",
      location: "",
      secondIcon: null,
      isShowSearch: true,
    },
  ],
  [IRoleType.DOCTOR]: [
    {
      path: "/availability-settings",
      icon: <GetImages name="SettingOrangeIcon" width="36" height="36" />,
      title: "Availability Settings",
      location: "",
      secondIcon: <GetImages name="CloseIcon" width="24" height="24" />,
      isShowSearch: false,
    },
    {
      path: "/availability-create",
      icon: <GetImages name="SettingGreyedIcon" width="24" height="24" />,
      title: "Availability Settings",
      location: "",
      secondIcon: <GetImages name="CloseIcon" width="24" height="24" />,
      isShowSearch: false,
    },
    {
      path: "/schedule",
      icon: <GetImages name="ScheduleNavIcon" width="36" height="36" />,
      title: "Schedule",
      location: "",
      secondIcon: <GetImages name="SettingGreyedIcon" width="24" height="24" />,
      isShowSearch: true,
    },
    {
      path: "/ip-dash",
      icon: <GetImages name="IpDashNavIcon" width="36" height="36" />,
      title: "IQ City Hospital",
      location: "Change",
      secondIcon: null,
      isShowSearch: true,
    },
    {
      path: "/chats",
      icon: <GetImages name="ChatNavIcon" width="36" height="36" />,
      title: "Chats",
      location: "",
      secondIcon: null,
      isShowSearch: true,
    },
  ],
};
interface INavItem {
  path: string;
  icon: React.ReactNode;
  title: string;
  location: string;
  secondIcon: React.ReactNode;
  isShowSearch: boolean;
}
const TopNavigation: React.FC = () => {
  const roleType = getFromLocalStorage("roleType");
  const [navItem, setNavItem] = useState<INavItem>();
  const location = useLocation();

  const setNavItemsData = () => {
    if (roleType === IRoleType.PATIENT || roleType == IRoleType.DOCTOR) {
      navItems[roleType].forEach((item, index) => {
        if (item.path.includes(location.pathname)) {
          setNavItem(item);
        }
      });
    }
  };

  useEffect(() => {
    setNavItemsData();
  }, [location]);
  return (
    <Grid item className={style.topNavigationContainer}>
      <Grid item className={style.topNavigationTitleContainer}>
        <Grid item className={style.topNavigationIcon}>
          {navItem?.icon}
        </Grid>
        <Grid item className={style.topNavigationTextContainer}>
          <Typography component={"p"} className={style.topNavigationTitle}>
            {navItem?.title}
          </Typography>
          {navItem?.location && (
            <Grid item className={style.topNavigationLocation}>
              <GetImages name="DoctorNavLocationIcon" width="12" height="12" />
              <Typography
                component={"p"}
                className={style.topNavigationLocationText}
              >
                {navItem?.location}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item className={style.topNavigationIconContainer}>
        {navItem?.isShowSearch && (
          <GetImages name="NavSearchIcon" width="24" height="24" />
        )}
        {navItem?.secondIcon && (
          <Grid item className={style.topNavigationSecondIconContainer}>
            {navItem?.secondIcon}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default TopNavigation;
