import React, { useEffect, useState } from "react";
import { BottomNavigation as Navigation } from "@mui/material";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import style from "./BottomNavigation.module.css";

import GetImages from "../../GetImages/GetImages";

import { getFromLocalStorage } from "../../../utils/localStorage";

import { IRoleType } from "../../../models/role";

interface INavItemInfo {
  path: string;
  icon: React.ReactNode;
  selectedIcon: React.ReactNode;
  label: string;
}
const navigation = {
  [IRoleType.PATIENT]: [
    {
      path: "/home",
      icon: <GetImages name="HomeIcon" width="24" height="24" />,
      selectedIcon: (
        <GetImages name="HomeSelectedIcon" width="24" height="24" />
      ),
      label: "Home",
    },
    {
      path: "/find-doctor",
      icon: <GetImages name="DoctorIcon" width="24" height="24" />,
      selectedIcon: (
        <GetImages name="DoctorSelectedIcon" width="24" height="24" />
      ),
      label: "Doctors",
    },
    {
      path: "/medicines",
      icon: <GetImages name="MedicineIcon" width="24" height="24" />,
      selectedIcon: (
        <GetImages name="MedicineSelectedIcon" width="24" height="24" />
      ),
      label: "Medicines",
    },
    {
      path: "/test-reports",
      icon: <GetImages name="TestIcon" width="24" height="24" />,
      selectedIcon: (
        <GetImages name="TestSelectedIcon" width="24" height="24" />
      ),
      label: "Tests",
    },
    {
      path: "/health-tips",
      icon: <GetImages name="TvIcon" width="24" height="24" />,
      selectedIcon: <GetImages name="TvSelectedIcon" width="24" height="24" />,
      label: "Health",
    },
  ],
  [IRoleType.DOCTOR]: [
    {
      path: "/schedule",
      icon: <GetImages name="ScheduleIcon" width="24" height="24" />,
      selectedIcon: (
        <GetImages name="ScheduleSelectedIcon" width="24" height="24" />
      ),
      label: "Schedule",
    },
    {
      path: "/ip-dash",
      icon: <GetImages name="IpDashIcon" width="24" height="24" />,
      selectedIcon: (
        <GetImages name="IpDashSelectedIcon" width="24" height="24" />
      ),
      label: "IP Dash",
    },
    {
      path: "/chats",
      icon: <GetImages name="ChatIcon" width="24" height="24" />,
      selectedIcon: (
        <GetImages name="ChatSelectedIcon" width="24" height="24" />
      ),
      label: "Chat",
    },
    {
      path: "/profile",
      icon: <GetImages name="ProfileIcon" width="24" height="24" />,
      selectedIcon: (
        <GetImages name="ProfileSelectedIcon" width="24" height="24" />
      ),
      label: "Profile",
    },
  ],
};

const BottomNavigation: React.FC = () => {
  const [navIndex, setNavIndex] = useState(0);
  const [navItems, setNavItems] = useState<INavItemInfo[]>();

  const navigate = useNavigate();

  const roleType = getFromLocalStorage("roleType");

  const navigateToPage = () => {
    if (roleType === IRoleType.PATIENT || roleType === IRoleType.DOCTOR) {
      navigate(navigation[roleType][navIndex].path);
    }
  };

  const setNavigationItemsByRoleType = () => {
    if (roleType === IRoleType.PATIENT || roleType === IRoleType.DOCTOR) {
      setNavItems(navigation[roleType]);
    }
  };

  useEffect(() => {
    navigateToPage();
  }, [navIndex]);

  useEffect(() => {
    setNavigationItemsByRoleType();
  }, []);
  return (
    <Grid item className={style.navigationContainer}>
      <Navigation
        showLabels
        value={navIndex}
        sx={{ padding: "5px 0px" }}
        onChange={(event, newValue) => setNavIndex(newValue)}
      >
        {navItems &&
          navItems.map((nav, index) => (
            <BottomNavigationAction
              label={nav.label}
              icon={navIndex === index ? nav.selectedIcon : nav.icon}
              key={index}
              sx={{
                color: "#A8A8A8",
                fontSize: "12px!important",
                lineHeight: "16px",
                fontWeight: "600!important",
                "&.Mui-selected": {
                  color: "#F15A29",
                  fontSize: "12px!important",
                  fontWeight: "600!important",
                },
              }}
            />
          ))}
      </Navigation>
    </Grid>
  );
};

export default BottomNavigation;
