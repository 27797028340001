import React, { Suspense, useEffect } from "react";

import Router from "./routes/Router";

import "./App.css";
import { saveToLocalStorage } from "./utils/localStorage";
import { IRoleType } from "./models/role";

const App: React.FC = () => {
  // Remove this code after integration of apis
  useEffect(() => {
    saveToLocalStorage("roleType", IRoleType.DOCTOR);
  }, []);
  return (
    <Suspense fallback={<>Loading....</>}>
      <Router />
    </Suspense>
  );
};

export default App;
